/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"

import { polyFluidSizing } from "./style-utils"
import Header from "./header"
import { ScrollLockManager } from "./ScrollLockManager"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: Times;
    ${polyFluidSizing("font-size", {
      350: 17,
      380: 21,
      576: 22,
      768: 22,
      992: 14,
    })};

    a {
      color: blue;
      word-spacing: normal;
      text-align: left;
    }
    line-height: 1rem;

    @media (min-width: 320px) {
      /* smartphones, iPhone, portrait 480x320 phones */
      /*  background-color: lightblue;*/
      line-height: 2rem;
    }

    @media (min-width: 481px) {
      /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
      /*background-color: brown;*/
      line-height: 2rem;
    }
    @media (min-width: 641px) {
      /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones*/
      /* background-color: blue;*/
      line-height: 2rem;
    }
    @media (min-width: 961px) {
      /* tablet, landscape iPad, lo-res laptops ands desktops */
      /* background-color: purple;*/
      line-height: 1.3rem;
    }
    @media (min-width: 1025px) {
      /* big landscape tablets, laptops, and desktops */
      /*  background-color: yellow;*/
      line-height: 1.3rem;
    }
    @media (min-width: 1281px) {
      /* hi-res laptops and desktops */
      /* background-color: pink;*/
      line-height: 1.3rem;
    }

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`

const theme = {
  headerHeight: "3.5vh" ,
}

const Content = styled.div`
  margin-top: ${props => props.theme.headerHeight};
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ScrollLockManager>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <Header siteTitle={data.site.siteMetadata.title} />
            <Content>
              <main>{children}</main>
              <footer />
            </Content>
          </>
        </ThemeProvider>
      </ScrollLockManager>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
