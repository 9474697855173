import React from 'react'

const ScrollLockContext = React.createContext(false)

export const ScrollLockManager = ({ children }) => {
  const [lockScroll, setLockScroll] = React.useState(false)

  return (
    <ScrollLockContext.Provider value={[lockScroll, setLockScroll]}>
      {children}
    </ScrollLockContext.Provider>
  )
}

export const useScrollLock = () => React.useContext(ScrollLockContext)
