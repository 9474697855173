import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled from "styled-components"
import Burger from "./Burger"

export const Container = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.headerHeight};
  @media (max-width: 960px) {
    height: 5vh;
  }
  background: white;
  z-index: 1;
  width: 100%;
`

export const Btn = styled.div`
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 960px) {
    font-size: 22px;
  }
  background: white;
  border-color: none;
  border-style: none;
  border-width: none;
  border-radius: none;
  :hover {
    font-style: italic;
    color: black;
  }
  cursor: pointer;
`


export const Menu = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
  width: 100%;
  height: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`
const MobileMenu = styled(Burger)`
  @media (min-width: 961px) {
    display: none;
  }
`

export const Link = styled(GatsbyLink)`
color: black;
text-decoration: none;
  :hover {
    font-style: italic;
    color: black;
  }
  &::after {
    display: block;
    content: "${props => props.children}";
    font-style: italic;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    color: black;
    padding: 0 2px;
  }
`

const Header = ({ siteTitle }) => (
  <Container>
    <Menu>
      Julia Rosenstock
      <Link to="/info">INFO</Link>
      <Link to="/imprint">IMPRINT</Link>
      <Link to="/dsgvo">DSVGO</Link>
    </Menu>
    <MobileMenu title="Julia Rosenstock">
      <Link to="/info">INFO</Link>
      <Link to="/imprint">IMPRINT</Link>
      <Link to="/dsgvo">DSVGO</Link>
    </MobileMenu>
  </Container>
)

export default Header
