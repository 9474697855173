import React from "react"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.theme.headerHeight};
  @media (max-width: 960px) {
    height: 5vh;
  }
  overflow: hidden;
  font-style: none;
color: black;
`

export const Btn = styled.div`
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 960px) {
    font-size: 40px;
    padding-top: 1px;
    padding-right: 1px;
    padding-bottom:1px;
  }

  background: white;
  border-color: none;
  border-style: none;
  border-width: none;
  border-radius: none;
  :hover {
    font-style: italic;
    color: black;
  }
  cursor: pointer;
  transform: rotate(${props => (props.shouldRotate ? "45deg" : "0")});
`

const Burger = ({ title, children, className }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  function toggle() {
    setIsOpen(!isOpen)
  }

  return (
    <Container className={className}>
      {isOpen ? children : title}
      <Btn shouldRotate={isOpen} onClick={toggle}>
        +
      </Btn>
    </Container>
  )
}

export default Burger
